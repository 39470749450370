$(function() {

	// button
	$(document).on('click', '#toggle-menu', function(){
		// add active class
		$('#toggle-menu').toggleClass('is-active');
		$('#page-navigation').slideToggle()
	});


});
